<script lang="ts"></script>

<main>
  <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe
      src="https://player.vimeo.com/video/139269807?h=a16d8c8b14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      title="Showreel Bas Kakes"
    />
  </div>
  <script src="https://player.vimeo.com/api/player.js"></script>
</main>

<style></style>
